import React, { Component } from "react"
import { graphql } from "gatsby"
import axios from "axios"
import { Link } from "gatsby"
import ContextConsumer from "../services/context"
import ReleaseListContianer from "../components/releaseList/releaseListContainer"
import Layout from "../components/layout"
import { getToken } from "../utils/getToken"

import FeaturedRelease from "../components/featuredRelease"

import {fetchAllReleases} from "../utils/fetchAllReleases"

class Djkicks extends Component {
  _isMounted = false
  state = {
    loading: true,
    error: false,
    releases: [{}],
    readyToLoad: false,
  }

  componentDidMount () {
    this._isMounted = true
    fetchAllReleases().then(releases => {
      if (this._isMounted) {
        const djkicksReleases = releases.filter( h => h.title.includes('DJ-Kicks') )
        this.setState({
          releases: djkicksReleases,
          featuredRelease: djkicksReleases[0],
          readyToLoad: true,
        })
      }
    })
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  render() {
    let featuredRelease, 
    backgroundImage;
    const releases = this.state.releases
  
    let releaseList = <ReleaseListContianer 
      releases={releases}
      title="DJ-Kicks"
      readyToLoad={this.state.readyToLoad}
    />

    if ( this.state.readyToLoad ) {
      const featuredRelease = this.state.releases[0]
      console.log('djk featured', this.state.releases[0])
      const featuredBgImgUrl = featuredRelease.imageUrl
      const backgroundImageCx="fixed opacity-25 bgBlur"
      const backgroundImageStyle= {
        backgroundImage: "url(" + featuredBgImgUrl +")",
        backgroundSize: "cover",
        width: '120%',
        height: '120%',
        top: '-20%',
        left: '-20%',
        zIndex: -10,
      }
      backgroundImage = <div className={backgroundImageCx} style={backgroundImageStyle}></div>
    } else {
      featuredRelease = ""
      backgroundImage = ""
      // Add loaing state here
    }
    
    return (
      <>
        {backgroundImage}
        <FeaturedRelease 
          release={this.state.releases[0]}
          dataFetched={this.state.readyToLoad}
          releaseType={"release"}
        />
        {releaseList}
      </>

    )

  }

}

export const query = graphql`
  {
    allModules(filter: {view: {slug: {eq: "djkicks"}}}) {
      edges {
        node {
          type
          title
          id
          itemId
        }
      }
    }
  }
`


Djkicks.contextType = ContextConsumer;
export default Djkicks
