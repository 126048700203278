import axios from "axios"
import { getToken } from "./getToken"

let releases = []
const fetchRemainingReleasePage = async function (page, options) {
  return axios.get('https://api.ochre.io/v1/music/releases?page=' + page, options).then(response => {
    return response.data.results
  })
  .catch(error => {
    console.log(error)
  })
}

export const fetchAllReleases =  async () => {
  const url = 'https://api.ochre.io/v1/music/releases?'

  return getToken().then(options => {
    return axios.get('https://api.ochre.io/v1/music/releases?', options).then(response => {
      const pages = response.data.summary.totalPages
      releases = response.data.results
      const remainingReleasePages = []
      for (let i = 2; i <= pages; i++) {
        remainingReleasePages.push( fetchRemainingReleasePage(i, options) )
      }

      return Promise.all( remainingReleasePages ).then( (results) => {
        const mergedResults = [].concat.apply(results)
        const allReleases = [].concat.apply(releases, mergedResults)
        return allReleases
      })

    }).catch(error => {
      return console.log('ERROR', error)
    })
  }) 

}

