import React, { Component } from "react"
import { Link } from "gatsby"
import slugify from '../utils/slugify'
import OnImagesLoaded  from 'react-on-images-loaded'
import { fetchMediaItems } from "../utils/fetchMediaItems"
import ContextConsumer from "../services/context"
import DefaultImg from "./defaultImg"
import Skeleton from "./skeleton"
import NewsletterForm from "./newsletterForm"


class FeaturedRelease extends Component {

  state = {
    imgLoaded: false,
    elInit: false,
    imgPlaceholderLoaded: false,
  }
  
  componentDidMount() {
    this.setState({
      elInit: true
    })
  }
  

  playTrack() {
    let id
    if (this.props.releaseType == "release") {
      id = this.props.release.id
    } else {
      id = this.props.release.reference.id
    }
    fetchMediaItems(id, 'release').then(results => {
      this.context.data.loadTracksAndPlay( results )
    })
  }

  render() {
    const componentReady = this.props.dataFetched
    const featuredRelease = this.props.release
    const releaseType = this.props.releaseType
    let url, title, artist, image, hideIfDjKicksCx

    if ( componentReady ) {

      title = featuredRelease.title
      if ( releaseType == "release" ) {
        artist = featuredRelease.artist.name
        image = featuredRelease.images[4].url
      } else {
        artist = featuredRelease.reference.resource.artist.name
        image = featuredRelease.imageUrl
      }
      if ( title.includes('DJ-Kicks') ) {
        hideIfDjKicksCx = "hidden"
      }

      const artistSlug = slugify( artist )
      const titleSlug = slugify( title )
      url = `/${artistSlug}/${titleSlug}`
    } else {
      title = <Skeleton />
      artist = <Skeleton />
    }

    // Animation
    // Animate Parent container on load
    let animText, elFadeIn, elFadeInDefault, elFadeIn1
    const animTextDefault = {
      transitionProperty: 'all',
      transitionDuration: '.6s', 
      transformOrigin: 'right',
      transitionDelay: '1s'
    }
    elFadeInDefault = {
      transitionProperty: 'all',
      transitionDuration: '.2s', 
    }
    if ( componentReady && this.state.imgLoaded ) {
      animText = { transform: 'scaleX(1)', ...animTextDefault }
      elFadeIn = { opacity: 1 }
    } else {
      animText = { transform: 'scaleX(0)', ...animTextDefault }
      elFadeIn = { opacity: 0 }
    }

    elFadeIn1 = {transitionDelay: '1.6s', ...elFadeInDefault, ...elFadeIn}

    // Anim artwork on load
    let animArtwork, animArtworkDefault

    animArtworkDefault = {
      transitionProperty: 'all',
      transitionDuration: '.2s', 
      transitionDelay: '.5s'
    }

    if ( this.state.imgLoaded ) {
      animArtwork = {opacity: 1, ...animArtworkDefault }
    } else {
      animArtwork = {opacity: 0, ...animArtworkDefault }
    }

    return (

      <section 
        className="flex relative flex-col md:flex-row lg:flex-row xl:flex-row m-4 md:m-6 lg:m-6 xl:m-6 z-0"
      >
        <div className="relative order-2 md:order-1 lg:order-1 xl:order-1 self-center w-full md:w-4/8 lg:w-4/8 xl:w-4/8 uppercase">
          <Link to={url} style={animText}>
            <div className="right-0 hidden md:block lg:block xl:block absolute md:relative lg:relative xl:relative w-2/8 border border-black md:border-b-0 lg:border-b-0 xl:border-b-0 mono-small bg-white p-1 w-auto text-center">
              Featured
            </div>
            <div className="hover:bg-clearWhite block text-2xl md:text-4xl lg:text-4xl xl:text-4xl border border-black border-t-0 md:border-t lg:border-t xl:border-t md:border-r-0 lg:border-r-0 xl:border-r-0 p-4 md:p-8 lg:p-8 xl:p-8">
              <div style={elFadeIn1} className="leading-none">
                { title }
              </div>
              <div style={elFadeIn1} className={`${hideIfDjKicksCx} leading-none`}>
                — {artist }
              </div>            
            </div>
          </Link>
          <div onClick={() => { this.playTrack() }} className="cursor-pointer w-4/8 border border-black border-t-0 hover:bg-gray-100 uppercase bg-white p-2 px-4 w-auto">
            <span className="pr-2">►</span>
            Listen
          </div>
          <div className="mt-8">
            <div className="mono text-xs uppercase black">Sign up to K7 release updates</div>
            <NewsletterForm 
              size="small"
            />
          </div>
        </div>

        <Link to={url} className="relative order-1 md:order-2 lg:order-2 xl:order-2 w-full md:w-4/8 lg:w-4/8 xl:w-4/8 border border-black hover:border-black p-6 bg-white">
          <OnImagesLoaded onLoaded={() => this.setState({ imgLoaded: true })}>
            <DefaultImg />
            <img className="absolute w-full top-0 left-0 p-6" style={animArtwork} src={image} />
          </OnImagesLoaded>
        </Link>
      </section>
    )

  }
}
FeaturedRelease.contextType = ContextConsumer;
export default FeaturedRelease
