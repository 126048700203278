import axios from "axios"
import { getToken } from "./getToken"

export const fetchMediaItems = (id, reference) => {
  const baseUrl = 'https://api.ochre.io/v1/content/media-items?'
  let url
  if ( reference == 'track') {
    url = 'reference_type=track&type=audio&reference_id=' + id
  }

  if ( reference == 'release' ) {
    url = 'reference_type=release&type=audio&reference_id=' + id
  }

  return getToken().then(options => {
    return axios.get(baseUrl + url, options)
    .then(response => {
      return response.data.results 
    }).catch(error => {
      return console.log('ERROR', error)
    })
  }) 

}

